<template>
	<div class="about">
		<!-- <div class="nybanner_box">
			<img src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/shouye_1.png" alt="">
			<router-link to="/science_child1" tag="div">
				<img class="baominguttom" src="../../static/wenchang/2.png" alt="">
			</router-link>
		</div> -->
		<div class="query_box" style="position: relative;" v-if="inwidth > 768">
			<img src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/shouye_1.png" alt="" class="query_map">
			<img class="baominguttom" src="../../static/wenchang/2.png" alt="" @click="geotoyem">
			<img id="one" src=" https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/%E5%A4%A7%E8%B5%9B%E7%AE%80%E4%BB%8B.png" class="query_map" />
			<img id="two" src=" https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/%E5%A4%A7%E8%B5%9B%E8%BF%9B%E7%A8%8B.png" class="query_map" />
			<img id="sui" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/shouye_5.png" class="query_map" />
			<!-- <img id="fo"  src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/shouye_2.png" class="query_map" /> -->
			<img id="fo" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/%E8%AF%84%E9%80%89%E6%A0%87%E5%87%86.png" class="query_map" />
			<img id="fv" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/%E5%8F%82%E8%B5%9B%E9%A1%BB%E7%9F%A5%E6%94%B91.png" class="query_map" />
			<img id="six" src="../../static/wenchang/3.png" class="query_map" />
			<img id="sw" src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/%E5%8F%82%E8%B5%9B%E5%A3%B0%E6%98%8E.png" class="query_map" />
			<img src="../../static/wenchang/4.png" class="query_map" />
		</div>
		<div class="query_box" style="position: relative;" v-if="inwidth < 768">
			<img src="https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/system_file/%E7%A7%BB%E5%8A%A8%E7%AB%AF-h5%E7%A5%9E.png" class="query_map" />
			<img class="baominguttom" src="../../static/wenchang/2.png" alt="" @click="geotoyem">
		</div>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
			<span>请前往pc端网页进行报名！</span>
			<span id="html">https://wenchuang-web.123bingo.cn/#/</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleClose">复制网址</el-button>
			</span>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="dialogVisible2" width="20%" :before-close="handleClose">
			<span style="color: red;">活动报名已经结束！</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible2 = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "query",
	data() {
		return {
			inwidth: window.innerWidth,
			dialogVisible: false,
			dialogVisible2: false,
		}
	},
	methods: {
		geotoyem() {
			if (this.inwidth < 768) {
				this.dialogVisible2 = true
				return
			}
			this.dialogVisible2 = true
			// if (localStorage.getItem('phone')) {
			// 	this.$router.push('/science_child2')
			// } else {
			// 	this.$router.push('/science_child1')
			// }
		},
		handleClose() {
			const h5 = document.getElementById('html').innerText;
			console.log(h5);

			var input = document.createElement('input');
			input.setAttribute('id', 'copyInput');

			input.setAttribute('value', h5);

			document.getElementsByTagName('body')[0].appendChild(input);

			document.getElementById('copyInput').select();

			document.execCommand('copy')

			document.getElementById('copyInput').remove();
			this.$message({
				message: '复制成功！',
				type: 'success'
			});
			this.dialogVisible = false
		}
	}
};
</script>

<style lang="less">
.baominguttom {
  width: 223px !important;
  height: 110px !important;
  position: absolute;
  top: 6%;
  left: 61%;
  z-index: 999;
  cursor: pointer;
}

.nybanner_box {
  position: relative;
  width: 100%;
  height: 100vh;
  // height: 960px;
  /* margin-top: 88px; */
}

.nybanner_box img {
  width: 100%;
  height: 100vh;
  // height: 100%;
  object-fit: cover;
}

.about {
  background-color: #ffffff;
  margin-top: 71px;

  .title_icon {
    /* width: 5.85rem; */
    height: 0.6rem;
  }

  .box1 {
    padding-top: 0.7rem;
  }

  .query_box {
    position: relative;
    // padding-bottom: 1rem;
    // min-height: 6rem;
  }

  .query_map {
    display: block;
    width: 100%;
    // margin: 0 auto;
    // margin-top: .8rem;
  }

  .query_cont {
    width: 50%;
    position: absolute;
    left: 50%;
    margin-left: -25%;
  }

  .query_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.6rem auto 0.35rem auto;
    border: 1px solid #333;
    height: 0.88rem;
    line-height: 0.88rem;
    padding-left: 0.3rem;
    background: #fff;
  }

  .query_search input {
    flex: 1;
    height: 0.2rem;
    line-height: 0.2rem;
    border: none;
    margin-left: 0.3rem;
    border-left: 2px solid #333;
    padding-left: 0.3rem;
    font-size: 0.2rem;
    color: #333;
  }

  .search_bt {
    width: 1.9rem;
    font-size: 0.2rem;
    background: #ffb900;
    text-align: center;
    height: 100%;
    color: #fff;
    cursor: pointer;
  }

  .query_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.3rem;
  }

  .query_list span {
    display: block;
    font-size: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.25rem;
    padding-right: 0.2rem;
    border-right: 2px solid #333;
    height: 0.2rem;
    line-height: 0.2rem;
  }

  .query_list img {
    width: 0.35rem;
    height: 0.35rem;
  }

  .query_list_p {
    flex: 1;
    line-height: 0.3rem;
    color: #666;
    font-size: 0.16rem;
  }

  @media (max-width: 1560px) {
    .query_cont {
      width: 70%;
      margin-left: -35%;
    }
  }
}

@media (max-width: 1280px) {
  .about .query_box {
    min-height: 7rem;
  }
}

@media (max-width: 480px) {
  .baominguttom {
    width: 110px !important;
    height: 57px !important;
    position: absolute;
    top: 5%;
    left: 39%;
    z-index: 999;
    cursor: pointer;
  }
}
</style>